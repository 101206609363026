import React, { useEffect } from "react";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
import { useNavigate } from "react-router-dom";

function QrCodeReader() {
  const navigate = useNavigate();

  useEffect(() => {
    const scanner = new Html5Qrcode(
      "reader",
      {formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE], verbose: false}
    );

    const qrCodeSuccessCallback = (decodedText: string) => {
      scanner.stop();
      navigate(`/dashboard/lot/validate/${decodedText}`);
  };

  const qrCodeErrorCallback = (error: any) => {
    console.log(error);
};

    const config = { fps: 10, qrbox: { width: 250, height: 250 } };

    scanner.start({ facingMode: "environment" }, config, qrCodeSuccessCallback, qrCodeErrorCallback);

  }, [navigate]);

  return (
    <>
      <h1>Qr - SCAN</h1>
      <div id="reader"></div> 
    </>
  );
}

export default QrCodeReader;
