import { configureStore } from '@reduxjs/toolkit';
import { campagnesReducer } from '../slices/CampagneSlice';

const store = configureStore({
    reducer: {
        campagnes: campagnesReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;