import { Outlet } from "react-router-dom";
import RequiredAuth from "../auth/RequiredAuth";
import { Provider } from "react-redux";
import store from "../dashboard/store/Store";

function RequiredAuthOutlet(){
    return (
        <Provider store={store}>
        <RequiredAuth>
            <Outlet/>
        </RequiredAuth>
        </Provider>
    )
}

export default RequiredAuthOutlet;