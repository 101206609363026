import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../style/shared/popup.css";

interface PopupProps {
  message: string;
  btn: boolean;
  onBtnClick?: ()=>void;
  delay?: number;
  redirectTo?: string;
}

const Popup: React.FC<PopupProps> = ({
  message,
  btn,
  onBtnClick,
  delay = 3000,
  redirectTo = "",
}) => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (btn === false) {
      const timer = setTimeout(() => {
        setShow(false);
        navigate(redirectTo);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [btn, delay, navigate, redirectTo]);

  const handleClick = ()=>{
    if(onBtnClick) onBtnClick();
    setShow(false);
  }

  return show ? (
    <div className="popup-overlay">
      <div className="popup">
        <div className="text">
          <p>{message}</p>
        </div>
        {btn && (
          <div className="btn">
            <button onClick={()=>handleClick()}>Suivant</button>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Popup;
