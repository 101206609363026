import { useEffect, useState } from "react";
import validator from "validator";
import Wheel from "../../../game/Wheel";
import LogoOrange from "../../../images/logo-orange.png";
import LogoWhite from "../../../images/logo-volteco.jpeg";
import { useAsyncOperation } from "../../../shared/AsyncOperation";
import Popup from "../../../shared/Poppup";
import "../../../style/wheelPage.css";
import QuestionnaireForm from "./QuestionnaireForm";
import QuizzForm, { Data } from "./QuizzForm";

type Lot = {
  name: string;
  count: number;
};

type Questions = {
  question: string;
  response?: [string];
  proposalQuestion?: [string];
  withInput: boolean;
};

type Segment = {
  text: string;
  fillStyle?: string;
};

type ComponentState = "data" | "wheel" | "quizz";

type Props = {
  campagneId: string;
  companyId: string;
  campagneName: string;
  lots: Array<Lot>;
  questions: Questions[];
};

const options = {
  width: window.innerWidth < 600 ? 400 : 600,
  height: window.innerWidth < 600 ? 400 : 600,
  strokeStyle: "black",
};

function Quizz({ campagneId, companyId, lots, questions, campagneName }: Props) {
  const [{ busy, errorMessage }, setState] = useAsyncOperation();
  const [componentState, setComponentState] = useState<ComponentState>("data");
  const [segments, setSegments] = useState<Segment[]>([]);
  const [goodies, setGoodies] = useState("");
  const [dataId, setDataId] = useState("");
  const [mail, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const onSubmit = async ({ prenom, nom, email }: Data) => {
    setState({ busy: true });

    if (!validator.isEmail(email)) {
      return setState({ busy: false, errorMessage: "Votre adresse mail n'est pas valide" });
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      campagneId,
      companyId,
      name: nom,
      firstName: prenom,
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(`/api/user/register`, requestOptions)
      .then((data) => data.json())
      .then((result) => {
        setDataId(result.data._id);
        setEmail(result.data.email);
        setState({ busy: false });
        setComponentState("wheel");
      })
      .catch((error) => {
        console.log(error);
        setState({ busy: false });
      });
  };

  const shuffleArray = function <T>(array: Array<T>) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const registerLot = async (out: string) => {
    setState((s) => ({ ...s, busy: true }));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      campagneId,
      companyId,
      dataId,
      email: mail,
      lots: [out],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(`/api/user/generateLot`, requestOptions)
      .then((response) => response.text())
      .then(() => setState((s) => ({ ...s, busy: false })))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    const addlot = () => {
      const sommes = lots.reduce((acc, curr) => acc + curr.count, 0);
      let seg: Segment[] = [];

      for (let i = 0; i < lots.length; i++) {
        for (
          let j = 0;
          j < Math.round((lots[i].count / sommes) * 360) / 30;
          j++
        ) {
          seg.push({ text: lots[i].name });
          shuffleArray(seg);
        }
      }
      setSegments(seg);
    };

    addlot();
  }, [lots]);

  const endSpin = async (out: string) => {
    setGoodies(out);
    setShowPopup(true);
    await registerLot(out);
  };

  return (
    <>
      {componentState === "data" && (
        <QuizzForm
          disabled={busy}
          onSubmit={onSubmit}
          errorMessage={errorMessage}
        />
      )}

      {componentState === "wheel" && (
        <>
          <div className={campagneName === "Ronquière" ? `container-wheel container-${campagneName}` : "container-wheel container"}>
            {/* Faudra en faire un composant qui peut prendre deux style différent */}
            <img width="225px" src={campagneName === "Ronquière" ? LogoOrange : LogoWhite} alt="logo" />

            <Wheel
              segments={segments}
              options={options}
              functionEndSpin={endSpin}
              bgBtn={"#32b31a"}
            />
            <p className="Attila">
              ET TENTEZ DE REMPORTER
              DES GOODIES OU DES TICKETS À GRATTER
            </p>
          </div>

          <section className="image">
            <img src="/images/VOLTECO_WEBP/grid3.webp" alt="" />
            <img src="/images/VOLTECO_WEBP/grid1.webp" alt="" />
            <img src="/images/VOLTECO_WEBP/grid5.webp" alt="" />
            <img src="/images/VOLTECO_WEBP/grid2.webp" alt="" />
            <img src="/images/VOLTECO_WEBP/grid4.webp" alt="" />
          </section>

        </>
      )}

      {goodies && componentState === "quizz" && (
        <QuestionnaireForm
          questions={questions}
          goodies={goodies}
          campagneId={campagneId}
          companyId={companyId}
          dataId={dataId}
          next={() => setComponentState("data")}
        />
      )}

      {showPopup && (
        <Popup
          message={`Vous avez gagné "${goodies}"`}
          btn={true}
          onBtnClick={() => setComponentState("data")}
        />
      )}
    </>
  );
}

export default Quizz;
