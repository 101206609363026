import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Campagne } from "../campagnes/home/Campagnes";
import { fetchCampagnes, updateCampagne } from "../actions/CampagnesAction";

interface CampagneState {
    campagnes: Campagne[];
    loading: boolean;
    error: string | unknown | null;
}

const initialState: CampagneState = {
    campagnes: [],
    loading: false,
    error: null,
}

const campagneSlice = createSlice({
    name: "campagnes",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCampagnes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCampagnes.fulfilled, (state, action: PayloadAction<Campagne[]>) => {
                state.campagnes = action.payload;
                state.loading = false;
            })
            .addCase(fetchCampagnes.rejected, (state, action: PayloadAction<unknown>) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateCampagne.fulfilled, (state, action: PayloadAction<Campagne>) => {
                const index = state.campagnes.findIndex(campagne => campagne._id === action.payload._id);

                if (index !== -1) {
                    state.campagnes[index] = action.payload;
                }
            });
    },
});

export const { actions: campagnesActions, reducer: campagnesReducer } = campagneSlice;