import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { useParams } from 'react-router-dom';

const QRCodeGenerator: React.FC = () => {
  const { code = 'default' } = useParams<{ code: string }>();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <h1 className="text-2xl font-bold mb-4">QR Code pour: {code}</h1>
      <QRCodeSVG value={code} size={256} level="H" />
    </div>
  );
};

export default QRCodeGenerator;
