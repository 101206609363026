import { useEffect, useRef, useState } from "react";
import "../style/wheel.css";

type Segment = {
  text: string;
  fillStyle?: string;
};

type WheelOfFortuneProps = {
  width: number;
  height: number;
  lineWidth?: number;
  strokeStyle: string;
  spinDuration?: number;
  pointerAngle?: number;
};

class WheelOfFortuneState {
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private segments: Segment[];
  private width: number;
  private height: number;
  private lineWidth: number;
  private strokeStyle: string;
  private spinDuration: number;
  private isRunning: boolean;
  private animationId: number | null = null;
  private spinEaseFunction: (t: number) => number;
  private onSpinStart?: () => void;
  private rotation: number = 0;
  private indicatedSegment: Segment | null = null;
  private pointerAngle: number;

  constructor(
    canvas: HTMLCanvasElement,
    segments: Segment[],
    options: WheelOfFortuneProps
  ) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
    this.segments = segments;
    this.width = options.width;
    this.height = options.height;
    this.lineWidth = options.lineWidth || 2;
    this.strokeStyle = options.strokeStyle || "black";
    this.isRunning = false;
    this.spinDuration = options.spinDuration || 5000;
    this.spinEaseFunction = (t: number) => --t * t * t + 1;
    this.pointerAngle = options.pointerAngle || Math.PI / 2;
  }

  draw() {
    this.canvas.width = this.width + 10;
    this.canvas.height = this.height + 10;
    const centerX = this.width / 2;
    const centerY = this.height / 2;
    const radius = Math.min(this.width, this.height) / 2;
    const angleStep = (2 * Math.PI) / this.segments.length;

    this.ctx.clearRect(0, 0, this.width, this.height);
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.strokeStyle = this.strokeStyle;
    // this.ctx.font = "20px Arial";
    this.ctx.font = "bold 20px Arial";
    this.ctx.textAlign = "right";
    this.ctx.textBaseline = "middle";

    for (let i = 0; i < this.segments.length; i++) {
      const startAngle = i * angleStep - Math.PI / 2 - this.rotation;
      const endAngle = (i + 1) * angleStep - Math.PI / 2 - this.rotation;
      const angle = startAngle + angleStep / 2;
      const text = this.segments[i].text;
      let fillStyle = i % 2 === 0 ? "#32B31A" : "#585353";

      // const fillStyle =
      //   this.segments[i].fillStyle ||
      //   `hsl(${i * (360 / this.segments.length)}, 70%, 70%)`;

      this.ctx.beginPath();
      this.ctx.arc(centerX, centerY, radius, startAngle, endAngle);
      this.ctx.lineTo(centerX, centerY);
      this.ctx.fillStyle = fillStyle;
      this.ctx.fill();
      this.ctx.stroke();
      this.ctx.save();
      this.ctx.translate(
        centerX + radius * 0.75 * Math.cos(angle),
        centerY + radius * 0.75 * Math.sin(angle)
      );
      this.ctx.rotate(angle);
      this.ctx.fillStyle = "white";
      this.ctx.fillText(text, 0, 0);
      this.ctx.restore();
    }

    this.drawPointer();
  }

  drawPointer() {
    const pointerLength = 40;
    const pointerWidth = 20;
    const centerX = this.width / 2;
    const centerY = 42;

    this.ctx.save();

    // Déplacer le contexte au centre de rotation de la roue
    this.ctx.translate(centerX, centerY);

    // Dessiner le pointeur
    this.ctx.beginPath();
    this.ctx.moveTo(0, 0);
    this.ctx.rotate(Math.PI / -2);
    this.ctx.lineTo(pointerLength, pointerWidth / 2);
    this.ctx.lineTo(pointerLength, -pointerWidth / 2);
    this.ctx.closePath();
    this.ctx.fillStyle = "white";
    this.ctx.fill();
  }

  spin() {
    if (this.isRunning) return;

    this.isRunning = true;

    if (this.animationId) {
      cancelAnimationFrame(this.animationId);
    }

    this.onSpinStart && this.onSpinStart();

    return new Promise((resolve: (value: string) => void) => {
      const startTime = Date.now();
      const startRotation = this.rotation;
      const targetRotation =
        startRotation + Math.PI * 2 * (10 + Math.random() * 10);
      const easeFunction = this.spinEaseFunction;

      const animate = () => {
        const time = Date.now() - startTime;
        const progress = easeFunction(Math.min(time / this.spinDuration, 1));
        const rotation =
          startRotation + (targetRotation - startRotation) * progress;
        this.rotation = rotation;
        this.draw();

        if (progress < 1) {
          this.animationId = requestAnimationFrame(animate);
        } else {
          this.animationId = null;
          this.isRunning = false;
          const segmentIndex = Math.floor(
            (this.rotation % (Math.PI * 2)) /
            ((Math.PI * 2) / this.segments.length)
          );

          this.indicatedSegment = this.segments[segmentIndex];
          resolve(this.segments[segmentIndex].text);
        }
      };

      this.animationId = requestAnimationFrame(animate);
    });
  }
}

type WheelProps = {
  segments: Segment[];
  options: WheelOfFortuneProps;
  bgBtn: string;
  functionEndSpin: (out: string) => void;
};

function Wheel({ segments, options, functionEndSpin, bgBtn }: WheelProps) {
  const canvas = useRef<HTMLCanvasElement>(null);
  const button = useRef<HTMLButtonElement>(null);
  const [btn, setBtn] = useState<HTMLButtonElement>();
  const [canvass, setCanvas] = useState<HTMLCanvasElement>();

  useEffect(() => {
    setCanvas(canvas.current!);
    setBtn(button.current!);
  }, [btn, canvass]);

  useEffect(() => {
    if (!canvass || !btn) return;

    const Wheel = new WheelOfFortuneState(canvass, segments, options);
    Wheel.draw();

    btn.addEventListener("click", async () => {
      const out = await Wheel.spin();
      if (!out) return;

      functionEndSpin(out);
    });
  }, [btn, canvass, options, segments, functionEndSpin]);

  return (
    <div className="wheel">
      <canvas id="wheel" ref={canvas}></canvas>
      <button ref={button} style={{ background: bgBtn }}><p>JOUEZ</p></button>
    </div>
  );
}

export default Wheel;
