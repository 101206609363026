import AuthForm from "./authForm";
import "../style/dashboard/log.css";

function AuthPage(){
    return (
        <div className="container">
          <div className="login-form">
            <h2>Connexion Entreprise</h2>
            <AuthForm />
          </div>
        </div>
      );
}

export default AuthPage;