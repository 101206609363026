import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAuth } from "../../auth/AuthProvider";
import { fetchCampagnes, updateCampagne } from "../actions/CampagnesAction";
import { AppDispatch, RootState } from "../store/Store";
import { Campagne, Lot, Question } from "./home/Campagnes";

import "../../style/dashboard/editCampagne.css";
import Lots from "./lots/LotsForm";
import QuestionsForm from "./questions/QuestionsForm";

function EditCampagne() {
  const campagnes = useSelector(
    (state: RootState) => state.campagnes.campagnes
  );
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const [campagne, setCampagne] = useState<Campagne>();

  if (!user) throw new Error("Invalid User");

  useEffect(() => {
    if (user && campagnes.length === 0) {
      dispatch(fetchCampagnes({ companyId: user.id }));
    }

    if (id) {
      const campagneWithId = campagnes.find((item) => item._id === id);

      if (!campagne) setCampagne(campagneWithId);
    } else if (!campagne) {
      const campagne: any = {
        campagneName: "",
        companyId: user.id,
        lots: [
          { name: "", count: 0 },
        ],
        questions: [
          {
            question: "",
            response: ["3"],
            proposalQuestion: ["", ""],
            withInput: false,
          },
          {
            question: "",
            response: [""],
            proposalQuestion: [],
            withInput: true,
          },
        ],
      };
      setCampagne(campagne);
    }
  }, [campagne, campagnes, id, user, setCampagne, dispatch]);

  const handleCampagneNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCampagne((oldCampagne) => ({
      ...oldCampagne!,
      campagneName: event.target.value,
    }));
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (campagne && id) {
      dispatch(updateCampagne(campagne));
    }

    if (campagne && !id) {
      try {
        const response = await fetch(
          `/api/company/createCampagne`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(campagne),
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data); // Faites quelque chose avec la réponse JSON
        } else {
          const errorData = await response.json();
          console.error("Une erreur est survenue :", errorData);
        }
      } catch (error) {
        console.error("Une erreur est survenue lors de la requête :", error);
      }
    }
  };

  const handleUpdateLot = (index: number, lot: Lot) => {
    if (!campagne) throw new Error("Campagne Not found");

    setCampagne((oldCampagne) => {
      const newLots = [...oldCampagne!.lots];
      newLots[index] = lot;
      return {
        ...oldCampagne!,
        lots: newLots,
      };
    });
  };

  const handleAddLot = () => {
    if (!campagne) throw new Error("Campagne Not found");

    setCampagne((oldCampagne) => {
      const newLots = [
        ...oldCampagne!.lots,
        { name: "", count: 0 },
      ];
      return {
        ...oldCampagne!,
        lots: newLots,
      };
    });
  };

  const handleRemoveLot = (name: string) => {
    if (!campagne) throw new Error("Campagne Not found");

    setCampagne((oldCampagne) => {
      const rmLot = [...oldCampagne!.lots].filter((item) => item.name !== name);

      return {
        ...oldCampagne!,
        lots: rmLot,
      };
    });
  };

  const handleEditQuestion = (index: number, question: Question) => {
    if (!campagne) throw new Error("Campagne Not found");

    setCampagne((oldCampagne) => {
      const newQuestions = [...oldCampagne!.questions];
      newQuestions[index] = question;

      return {
        ...oldCampagne!,
        questions: newQuestions,
      };
    });
  };

  const handleRemoveQuestion = (index: number) => {
    if (!campagne) throw new Error("Campagne Not found");

    setCampagne((oldCampagne) => {
      const rmQuestion = [...oldCampagne!.questions].filter(
        (item, key) => key !== index
      );

      return {
        ...oldCampagne!,
        questions: rmQuestion,
      };
    });
  };

  const handleRmProposalQuestion = (
    question: Question,
    indexQuestion: number,
    text: string
  ) => {
    const newProposalQuestion = [...question.proposalQuestion!].filter(
      (items) => items !== text
    );

    handleEditQuestion(indexQuestion, {
      ...question,
      proposalQuestion: newProposalQuestion,
    });
  };

  const handleEditProposalQuestion = (
    question: Question,
    indexQuestion: number,
    indexProposalQuestion: number,
    text: string
  ) => {
    const newProposalQuestion = [...question.proposalQuestion!];
    newProposalQuestion[indexProposalQuestion] = text;

    handleEditQuestion(indexQuestion, {
      ...question,
      proposalQuestion: newProposalQuestion,
    });
  };

  const handleAddProposalQuestion = (index: number) => {
    if (!campagne) throw new Error("Campagne Not found");

    setCampagne((oldCampagne) => {
      const newQuestions = [...oldCampagne!.questions];
      const newProposalQuestion = "";

      newQuestions[index] = {
        ...newQuestions[index],
        proposalQuestion: [
          ...newQuestions[index].proposalQuestion,
          newProposalQuestion,
        ],
      };

      return {
        ...oldCampagne!,
        questions: newQuestions,
      };
    });
  };

  const handleEditResponse = (index: number, response: string[]) => {
    if (!campagne) throw new Error("Campagne Not found");

    setCampagne((oldCampagne) => {
      const newQuestions = [...oldCampagne!.questions];
      newQuestions[index] = {
        ...newQuestions[index],
        response: response,
      };

      return {
        ...oldCampagne!,
        questions: newQuestions,
      };
    });
  };

  const handleAddQuestionWithProposal = () => {
    if (!campagne) throw new Error("Campagne Not found");

    setCampagne((oldCampagne) => {
      const newQuestions: any = [...oldCampagne!.questions];
      newQuestions.push({
        _id: undefined,
        question: "",
        response: [""],
        proposalQuestion: ["", "", ""],
        withInput: false,
      });

      return {
        ...oldCampagne!,
        questions: newQuestions,
      };
    });
  };

  const handleAddQuestionWithoutProposal = () => {
    if (!campagne) throw new Error("Campagne Not found");

    setCampagne((oldCampagne) => {
      const newQuestions: any = [...oldCampagne!.questions];
      newQuestions.push({
        _id: undefined,
        question: "",
        response: [],
        proposalQuestion: [],
        withInput: true,
      });

      return {
        ...oldCampagne!,
        questions: newQuestions,
      };
    });
  };

  if (!campagne) return <h1>Campagne not Found</h1>;

  return (
    <>
      <h1 className="heading">Edit Campagne</h1>

      <form className="form" method="post" onSubmit={handleFormSubmit}>
        <div className="form-field">
          <label htmlFor="campagne-name bold-text">Nom de votre campagne</label>
          <input
            type="text"
            id="campagne-name"
            value={campagne?.campagneName}
            onChange={handleCampagneNameChange}
            placeholder="Nom de la campagne"
            required
          />
        </div>
        <div className="campagne-lots">
          <Lots
            lots={campagne.lots}
            handleAddLot={handleAddLot}
            handleRemoveLot={handleRemoveLot}
            handleUpdateLot={handleUpdateLot}
          />

          <div className="questions-container">
            <QuestionsForm
              questions={campagne.questions}
              handleEditQuestion={handleEditQuestion}
              handleRemoveQuestion={handleRemoveQuestion}
              handleAddProposalQuestion={handleAddProposalQuestion}
              handleEditProposalQuestion={handleEditProposalQuestion}
              handleRmProposalQuestion={handleRmProposalQuestion}
              handleEditResponse={handleEditResponse}
            />
          </div>

          <div className="add-question">
            <button type="button" onClick={handleAddQuestionWithProposal}>
              Add question with proposal question
            </button>
            <button type="button" onClick={handleAddQuestionWithoutProposal}>
              Add open question
            </button>
          </div>
        </div>

        <button type="submit">Enregistrer</button>
      </form>
    </>
  );
}

export default EditCampagne;
