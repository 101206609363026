import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
// import { AsyncOperation } from "../shared/asyncOperation";

export type User = {
  company: string;
  id: string;
}
type ContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
};

const AuthContext = createContext<ContextType>({
  user: null,
  setUser: () => { },
});

export function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }: PropsWithChildren) {
  const [{ busy }, setState] = useState({ busy: true });
  const [user, setUser] = useState<User | null>(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/auth/isAuth`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const data = await response.json();
        console.log(data);
        setState(() => ({ busy: false }));
        setUser(data.user);
      } catch (error) {
        // console.error("Erreur lors de la récupération des données:", error);
        setState(() => ({ busy: false }));
        setUser(null);
      }
    };

    fetchData();
  }, []);

  if (busy) {
    return <h1>Loading...</h1>;
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
