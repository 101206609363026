import { useAuth } from "../auth/AuthProvider";
import Campagnes from "./campagnes/home/Campagnes";

function DashboardPage() {
  const user = useAuth();

  console.log(user);
  return (
    <>
      <h1>Dashboard</h1>
      <Campagnes />
    </>
  );
}

export default DashboardPage;
