import { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth } from "../../../auth/AuthProvider";
import Popup from "../../../shared/Poppup";
import "../../../style/dashboard/campagnesList.css";
import { fetchCampagnes } from "../../actions/CampagnesAction";
import { AppDispatch, RootState } from "../../store/Store";

export type Lot = {
  _id?: string;
  name: string;
  count: number;
};

export type Question = {
  _id: string;
  question: string;
  response: string[];
  proposalQuestion: string[];
  withInput: boolean;
};

type CampagneInfo = {
  count: number;
  email: string;
  name: string;
  firstName: string;
  sucess: boolean;
};

export interface Campagne {
  _id?: string;
  campagneName: string;
  companyId: string;
  lots: Array<Lot>;
  questions: Array<Question>;
}

function Campagnes() {
  const { user } = useAuth();
  const campagnes = useSelector(
    (state: RootState) => state.campagnes.campagnes
  );
  const dispatch = useDispatch<AppDispatch>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [campagneInfo, setCampagneInfo] = useState<CampagneInfo>();

  if (!user) throw Error("User is not valid");

  const handleCampagneInfo = async (e: FormEvent, id: string) => {
    e.preventDefault();
    const request = await fetch(
      `/api/company/winner/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    const data: CampagneInfo = await request.json();
    setCampagneInfo(data);
    setShowPopup(true);
  };

  useEffect(() => {
    dispatch(fetchCampagnes({ companyId: user.id }));
  }, [dispatch, user]);

  return (
    <div className="campagne-list">
      <div className="campagne-new">
        <Link to="nouvelle-campagne">Créer une campagne</Link>
        <Link to="scan">
          <i className="fas fa-qrcode"></i>
        </Link>
      </div>
      {campagnes.map((items, key) => (
        <div className="campagne">
          <Link
            to={`/campagne/${items._id}`}
            className="no-underline"
            key={key}
          >
            <div className="campagne-info">
              <p>{items.campagneName}</p>
              <div style={{ display: "flex" }}>
                <div>
                  <Link
                    to={`/dashboard/campagne/${items._id}/edit`}
                    className="edit-button"
                  >
                    Edit
                  </Link>
                </div>
                <div onClick={(event) => handleCampagneInfo(event, items._id!)}>
                  <i className="fas fa-info-circle"></i>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
      {showPopup && (
        <Popup
          message={`Il y a ${campagneInfo?.count
            } participant(s). Le gagnant est ${campagneInfo?.firstName || "(aucun gagnant)"
            } ${campagneInfo?.name || ""} (${campagneInfo?.email || "aucun mail"
            })`}
          btn={true}
          onBtnClick={() => setShowPopup(false)}
        ></Popup>
      )}
    </div>
  );
}

export default Campagnes;
