import { Question } from "../home/Campagnes";

type Props = {
  questions: Question[];
  handleEditQuestion: (index: number, question: Question) => void;
  handleRemoveQuestion: (index: number) => void;
  handleEditProposalQuestion: (
    question: Question,
    indexQuestion: number,
    indexProposalQuestion: number,
    text: string
  ) => void;
  handleRmProposalQuestion: (
    question: Question,
    indexQuestion: number,
    text: string
  ) => void;
  handleAddProposalQuestion: (index: number) => void;
  handleEditResponse: (index: number, response: string[]) => void;
};

function QuestionsForm({
  questions,
  handleEditQuestion,
  handleRemoveQuestion,
  handleEditProposalQuestion,
  handleRmProposalQuestion,
  handleAddProposalQuestion,
  handleEditResponse,
}: Props) {
  return (
    <>
      {questions.map((question, index) => (
        <div className="question-box" key={question._id}>
          <div className="form-field question-field">
            <label htmlFor={`question-${index}`} className="bold-text">
              Question
            </label>
            <input
              type="text"
              id={`question-${index}`}
              placeholder="Question"
              value={question.question}
              onChange={(e) =>
                handleEditQuestion(index, {
                  ...question,
                  question: e.target.value,
                })
              }
            />
            <button
              className="remove-button"
              onClick={() => handleRemoveQuestion(index)}
              type="button"
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>

          {!question.withInput && (
            <div className="proposal-question">
              <ul>
                {question.proposalQuestion?.map((item, key) => (
                  <li key={key} className="form-field proposal-question-field">
                    <label htmlFor={`proposal-question-${index}-${key}`}>
                      Proposition
                    </label>
                    <input
                      type="text"
                      id={`proposal-question-${index}-${key}`}
                      value={item}
                      placeholder="Proposition"
                      onChange={(e) =>
                        handleEditProposalQuestion(
                          question,
                          index,
                          key,
                          e.target.value
                        )
                      }
                    />
                    <button
                      className="remove-button"
                      type="button"
                      onClick={() =>
                        handleRmProposalQuestion(question, index, item)
                      }
                    >
                      X
                    </button>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => handleAddProposalQuestion(index)}
                type="button"
              >
                +
              </button>
            </div>
          )}

          <div className="form-field response-field">
            <label htmlFor={`response-${index}`}>Réponse :</label>
            <input
              type="text"
              id={`response-${index}`}
              value={question.response[0]}
              onChange={(e) => {
                const newResponse = [e.target.value];
                handleEditResponse(index, newResponse);
              }}
            />
          </div>
        </div>
      ))}
    </>
  );
}

export default QuestionsForm;
