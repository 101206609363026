import { Lot } from "../home/Campagnes";

type Props = {
  lots: Lot[];
  handleUpdateLot: (index: number, lot: Lot) => void;
  handleRemoveLot: (name: string) => void;
  handleAddLot: () => void;
};

function Lots({ lots, handleUpdateLot, handleRemoveLot, handleAddLot }: Props) {
  return (
    <div>
      {lots.map((lot, key) => (
        <div key={key} className="lot-container">
          <div className="form-field lot-name-field">
            <label htmlFor={`lot-name-${lot._id}`}>Nom du lot</label>
            <input
              type="text"
              id={`lot-name-${key}`}
              value={lot.name}
              placeholder="Nom du lot"
              onChange={(e) =>
                handleUpdateLot(key, { ...lot, name: e.target.value })
              }
            />
          </div>
          <div className="form-field lot-count-field">
            <label htmlFor={`lot-count-${key}`}>Nombre de lot</label>
            <input
              type="number"
              id={`lot-count-${key}`}
              value={lot.count}
              placeholder="Nombre de lot disponible"
              onChange={(e) =>
                handleUpdateLot(key, {
                  ...lot,
                  count: parseInt(e.target.value),
                })
              }
            />
          </div>

          <div className="remove-button-container">
            <button
              className="remove-button"
              onClick={() => handleRemoveLot(lot.name)}
              type="button"
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      ))}

      <div className="add-lots">
        <button onClick={handleAddLot} type="button">
          Ajouter un lot
        </button>
      </div>
    </div>
  );
}

export default Lots;
