import { PropsWithChildren } from "react";
import { useAuth } from "./AuthProvider";

function RequiredAuth({ children }: PropsWithChildren) {
    const { user } = useAuth();

    if (!user) {
        return <h1>Vous devez être connecté</h1>
    }
    return <>{children}</>
}

export default RequiredAuth; 
