import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAsyncOperation } from "../../shared/AsyncOperation";
import "../../style/HomePage.css";
import Quizz from "./quizz/Quizz";

type Lot = {
  name: string;
  count: number;
};

type Questions = {
  question: string;
  response?: [string];
  proposalQuestion?: [string];
  withInput: boolean;
};
type Campagne = {
  campagneName: string;
  companyId: string;
  _id: string;
  lots: Array<Lot>;
  questions: Array<Questions>;
};

function Game() {
  const [{ busy, errorMessage }, setState] = useAsyncOperation(true);
  const { id } = useParams();
  const [campagne, setCampagne] = useState<Campagne>();

  useEffect(() => {
    const getData = async () => {
      try {
        const request = await fetch(`/api/user/${id}`);
        const data = await request.json();

        setCampagne(data.data);
        setState((s) => ({ ...s, busy: false }));
      } catch (err) {
        console.log(err);
        setState({ busy: false });
      }
    };

    getData();
  }, [setState, id]);

  if (busy) return <h1>Loading...</h1>;
  if (errorMessage) return <h1>{errorMessage}</h1>;
  if (!campagne) return <h1>La campagne ne semble pas valide</h1>;

  return (
    <>
      {/* <div className="menu">
        <img src="https://www.accentjobs.be/static/e02db76db21ff853a33f6ca86319d010/b6acc/logo-white.png" alt="Accent Logo" />
    </div> */}
      <Quizz campagneId={campagne._id} companyId={campagne.companyId} lots={campagne.lots} questions={campagne.questions} campagneName={campagne.campagneName} />
    </>
  );
}

export default Game;
