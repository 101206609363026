import { createAsyncThunk } from '@reduxjs/toolkit';
import { Campagne } from '../campagnes/home/Campagnes';

export interface FetchCampagnesArgs {
    companyId: string;
}

const updateCampagneApi = async (campagne: Campagne): Promise<Campagne> => {
    const response = await fetch(`/api/company/editCampagne/${campagne._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(campagne),
        credentials: 'include',
    });

    if (!response.ok)
        throw new Error('Error updating campagne');
    const data = await response.json();
    return data;
}

export const updateCampagne = createAsyncThunk(
    `/campagnes/updateCampagne`,
    async (campagne: Campagne, { rejectWithValue }) => {
        try {
            const updatedCampagne = await updateCampagneApi(campagne);
            return updatedCampagne;
        } catch (error) {
            return rejectWithValue('Error updating campagne');
        }
    }
)

export const fetchCampagnes = createAsyncThunk<Campagne[], FetchCampagnesArgs>(
    'campagnes/fetchCampagnes',
    async ({ companyId }: FetchCampagnesArgs): Promise<Campagne[]> => {
        const response = await fetch(`/api/company/campagnes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ companyId }),
            credentials: 'include', // Or 'same-origin'
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.campagnes;
    }
);
