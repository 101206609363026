import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncOperation } from "../shared/AsyncOperation";
import { useAuth } from "./AuthProvider";

function AuthForm() {
  const [{ busy, errorMessage }, setState] = useAsyncOperation();
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const handleLogin = async () => {
    setState((s) => ({ ...s, busy: true }));
    try {
      console.log(process.env.REACT_APP_API_URL);
      const response = await fetch(`/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ company: companyName, password }),
        credentials: "include",
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Ne plus afficher", data);
        setUser(data.user);
        navigate("/dashboard");
      } else {
        // Erreur de connexion
        console.log("Erreur de connexion");
        console.log(data.error);
      }
      // setState({busy: false});
    } catch (error) {
      setState({ errorMessage: "Impossible de vous connecter", busy: false });
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div>
      <form>
        <div className="form-group">
          <label>Nom de l'entreprise:</label>
          <input
            type="text"
            value={companyName}
            placeholder="Entreprise"
            disabled={busy}
            required
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Mot de passe:</label>
          <input
            type="password"
            value={password}
            placeholder="Mot de passe"
            disabled={busy}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="button" disabled={busy} onClick={handleLogin}>
          Se connecter
        </button>

        {errorMessage && (
          <div className="error">
            <p>{errorMessage}</p>
          </div>
        )}
      </form>
    </div>
  );
}

export default AuthForm;
