import { useState } from "react";
import { useAsyncOperation } from "../../../shared/AsyncOperation";
import Popup from "../../../shared/Poppup";
import "../../../style/questionnaire.css";

type Questions = {
  question: string;
  response?: [string];
  proposalQuestion?: [string];
  withInput: boolean;
};

type Props = {
  campagneId: string;
  companyId: string;
  questions: Questions[];
  goodies: string;
  dataId: string;
  next: () => void;
};

function QuestionnaireForm({
  campagneId,
  companyId,
  questions,
  goodies,
  dataId,
  next
}: Props) {
  const [{ busy }, setState] = useAsyncOperation();
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [answers, setAnswers] = useState<{ [key: string]: string[] }>({});
  const [dataSent, setDataSent] = useState<{ [key: string]: string[] }>({});

  const handleChangeAnswers = (
    question: string,
    response: string,
    checked: boolean,
    isTextInput?: boolean
  ) => {
    setAnswers((prevAnswers) => {
      if (isTextInput) {
        setDataSent((prev) => ({ ...prev, [question]: [response] }));
        return {
          ...prevAnswers,
          [question]: [response],
        };
      } else if (checked) {
        return {
          ...prevAnswers,
          [question]: [...(prevAnswers[question] || []), response],
        };
      } else {
        return {
          ...prevAnswers,
          [question]: prevAnswers[question]?.filter(
            (answer) => answer !== response
          ),
        };
      }
    });
  };

  const scoreCalculate = () => {
    let score = 0;

    for (let question of questions) {
      if (
        JSON.stringify(answers[question.question]?.sort()) ===
        JSON.stringify(question.response?.sort())
      ) {
        score += 1;
      }
    }
    // alert(`Vous avez eu ${score} bonne reponse sur ${questions.length}`);
    return score;
  };

  const handleSubmit = () => {
    setState({ busy: true });
    const score = scoreCalculate();

    const data = Object.entries(dataSent).flatMap(([question, responses]) =>
      responses.map((response) => ({ question, response }))
    );

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      campagneId,
      companyId,
      dataId,
      score,
      questionResponse: data,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(`/api/user/resultQuizz`, requestOptions)
      .then((response) => response.text())
      .then(() => setIsEnd(true))
      .catch((error) => {
        console.log("error", error);
        setState({ busy: true });
      });
  };

  return (
    <>
      <h1>
        Essaie de gagner un cadeau suppémentaire en répondant aux questions
        suivantes
      </h1>

      <div className="conatiner-questions">
        {questions.map((question, index) => (
          <div key={index}>
            <div className="question-text">{question.question}</div>

            <div className="checkbox-group">
              {question.withInput === false ? (
                question.proposalQuestion?.map((proposalQuestion, i) => (
                  <div key={i} className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      id={`checkbox-${index}-${i}`}
                      value={proposalQuestion}
                      disabled={busy}
                      onChange={(e) =>
                        handleChangeAnswers(
                          question.question,
                          proposalQuestion,
                          e.target.checked
                        )
                      }
                    />
                    <label htmlFor={`checkbox-${index}-${i}`}>
                      {proposalQuestion}
                    </label>
                  </div>
                ))
              ) : (
                <input
                  type="text"
                  placeholder="Entrez votre réponse"
                  disabled={busy}
                  required
                  onChange={(e) =>
                    handleChangeAnswers(
                      question.question,
                      e.target.value,
                      true,
                      true // Ajoutez cet argument pour indiquer qu'il s'agit d'un input de type texte
                    )
                  }
                />
              )}
            </div>
          </div>
        ))}

        <button onClick={handleSubmit} disabled={busy}>
          Suivant
        </button>
      </div>

      {isEnd && (
        <Popup
          btn={true}
          delay={5000}
          message="Merci pour votre participation, récupérer votre lot sur le stand avec le code envoyé envoyé part mail !"
          onBtnClick={next}
        />
      )}
    </>
  );
}

export default QuestionnaireForm;
