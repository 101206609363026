import { Route, Routes } from "react-router-dom";
import AuthPage from "../auth/authPage";
import DashboardPage from "../dashboard/DashboardPage";
import SendLot from "../dashboard/Lots/SendLot";
import EditCampagne from "../dashboard/campagnes/CampagneForm";
import QrCodeReader from "../dashboard/qr-code-reader/QrCodeReader";
import QRCodeGenerator from "../users/code-qr/scan";
import Game from "../users/home/Game";
import RequiredAuthOutlet from "./RequiredAuthOutlet";


function AppRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route path="/codeqr/:code" element={<QRCodeGenerator />}></Route>
        <Route path="/" element={<AuthPage />}></Route>
      </Route>

      <Route path="/dashboard" element={<RequiredAuthOutlet />}>
        <Route path="" element={<DashboardPage />}></Route>
        <Route path="campagne/:id/edit" element={<EditCampagne />}></Route>
        <Route path="nouvelle-campagne" element={<EditCampagne />}></Route>
        <Route path="scan" element={<QrCodeReader />}></Route>
        <Route path="lot/validate/:code" element={<SendLot />}></Route>
      </Route>

      <Route
        path="/campagne/:id"
      >
        <Route path="" element={<Game />}></Route>
        {/* <Route path="game" element={<Game/>}></Route> */}
      </Route>

    </Routes>
  );
}

export default AppRoutes;
