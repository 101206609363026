import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const requestOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
  credentials: "include" as RequestCredentials,
};

function SendLot() {
  const { code } = useParams();
  const [lot, setLot] = useState<Array<String>>();
  const [isActive, setIsactive] = useState<boolean>(false);
  const navigate = useNavigate();

  const valisateLot = useCallback(
    (code: string) => {
      const request = async () => {
        const req = await fetch(
          `/api/company/lot/disable/${code}`,
          requestOptions
        );

        if (req) return navigate("/dashboard/scan");
      };

      request();
    },
    [navigate]
  );

  useEffect(() => {
    const fetchReq = async () => {
      const req = await fetch(
        `/api/company/lot/${code}`,
        requestOptions
      );

      if (req) {
        const data = (await req.json()).lot;
        console.log(data);
        setIsactive(data.status === "active");
        setLot(data.lots);
      }
    };

    fetchReq();
  }, [code]);

  return (
    <>
      {code ? (
        <>
          <h1>LOT - {code}</h1>
          {isActive ? (
            lot && (
              <div>
                <p>{lot.join(", ")}</p>
                <button onClick={() => valisateLot(code)}>
                  Le lot est remis
                </button>
              </div>
            )
          ) : (
            <p>Lot invalide</p>
          )}
        </>
      ) : (
        <h2>Erreur</h2>
      )}
    </>
  );
}

export default SendLot;
