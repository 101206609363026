import { FormEvent, useState } from "react";
import FormProps from "../../../shared/FormProps";
import "../../../style/data.css";


export type Data = {
  prenom: string;
  nom: string;
  email: string;
};
type Props = FormProps<Data>;

function QuizzForm({ disabled, onSubmit, errorMessage }: Props) {
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    onSubmit({ prenom, nom, email });
  };

  return (
    <div className="form-container">
      <form action="POST" onSubmit={handleSubmit} className="registerData">
        <h1 style={{ fontFamily: 'Lovelo-Black, sans-serif', color: 'white' }}>Remplissez ce formulaire et gagnez un de nos nombreux cadeaux</h1>
        <div>
          <div>
            <label htmlFor="firstName">Prénom:</label>
            <input
              minLength={2}
              type="text"
              placeholder="Prénom"
              id="firstName"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              disabled={disabled}
              required
              autoFocus
            />
          </div>

          <div>
            <label htmlFor="lastName">Nom:</label>
            <input
              minLength={2}
              type="text"
              placeholder="Nom"
              id="lastName"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              disabled={disabled}
              required
            />
          </div>
        </div>

        <div>
          <label htmlFor="email">Adresse email:</label>
          <input
            type="email"
            placeholder="Adresse mail"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={disabled}
            required
          />
        </div>

        <div>
          <button type="submit" disabled={disabled} className="submitBtn">
            Envoyer
          </button>
        </div>

        {errorMessage && (
          <div className="error">
            <p>{errorMessage}</p>
          </div>
        )}
      </form>
    </div>
  );
}

export default QuizzForm;
